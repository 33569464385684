@use '@angular/material' as mat;

@import 'theme-settings';
@include mat.core();

@include mat.all-component-themes($angular-default-theme);

.mat-app-background {
  background: rgb(240, 242, 245);
  min-height: 100vh;
}

.mat-mdc-dialog-content {
  background: rgb(240, 242, 245);
}
