@use '@angular/material' as mat;
@import '../theme-settings';

$primary: map-get($angular-default-theme, primary);

h1, .h1 {
  color: mat.get-color-from-palette($primary);
  font-weight: bold;

  @apply text-h1 leading-9;
}

h2, .h2 {
  color: mat.get-color-from-palette($primary);
  font-weight: bold;

  @apply text-h2 leading-8;
}

h3, .h3 {
  color: black;
  font-weight: bold;

  @apply text-h3 leading-8;
}

h4 {
  color: black;
  line-height: initial;
  font-weight: bold;

  @apply text-[18px] lg:text-[20px];
}

a {
  text-decoration: underline;
}

@layer base {
  .color-primary {
    color: mat.get-color-from-palette($primary) !important;
  }
}
