.feed-thumbup, .article-thumbup {
  display: flex;
  align-items: center;

  &__button {
    border: 1px solid $color-black !important;
    border-radius: 9999px !important;
    height: 35px !important;
    width: 35px !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;

    .ng-fa-icon {
      scale: .7;
    }
  }
}

.feed-readmore {
  padding-left: 8px;

  &__button {
    padding: 8px 15px;
    height: 35px;
    border-radius: 9999px;
  }
}
