/* You can add global styles to this file, and also import other style files */
@import 'theme.scss';
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

$primary: map-get($angular-default-theme, primary);
$warn: map-get($angular-default-theme, warn);
$accent: map-get($angular-default-theme, accent);

html, body {
  min-height: 100%;
  height: 100%;
  @apply text-[15px] md:text-[16px] lg:text-[18px];
}
body { margin: 0; font-family: 'Inter', sans-serif;  }

$h3-size: 1.67rem;

$layout-width: 1366px;

$color-white: #fff;
$color-red: #e40000;
$color-black: #000000;

$redButton-background: linear-gradient(to top right, #e40000, #a70000);
$greenButton-background: linear-gradient(180deg, #14ac19, #008505);

.background-green-fade {
  background: rgb(10,152,15);
  background: linear-gradient(90deg, rgba(10,152,15,1) 0%, rgba(0,133,5,1) 100%);
}

@layer components {
  .component-big-wrapper {
    @apply m-2 lg:ml-16 lg:mr-16 block;
  }

  .responsive-form {
    @apply grid grid-cols-1 lg:grid-cols-2 gap-2;
  }

  .tab-icon {
    @apply mr-1;
  }

  mat-button-toggle-group {
    @apply rounded-l-xl;
    @apply rounded-r-xl;
  }

  .layout-wrapper {
    max-width: $layout-width;
    @apply mx-auto px-1;

    &.mat-toolbar-row {
      @apply px-1;
    }
  }
}

.transparent-shadow-directive::before {
  display: block;
  width: 100%;
  height: 100px;
  position: absolute;
  bottom: 0;
  left: 0;
  content: " ";
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgb(240, 242, 245));
}

.article-wrapper iframe {
  border-radius: 12px;
}

@import "./styles/typography.scss";
@import "./styles/mdc-fixes.scss";

@import "./styles/view-content.scss";
@import "./styles/dashboard.scss";

@import "./styles/dialog.scss";
@import "./styles/form.scss";
