form {
  label {
    @apply ml-0.5;
  }

  label, p, a, mat-error {
    @apply text-sm;
  }

  textarea {
    @apply text-sm;
    box-shadow: inset 0 0 2px #9e9e9e;
    padding: 1rem 1.4rem !important;
    border-radius: 12px;
  }

  button[mat-flat-button] {
    height: 50px;
  }

  input:not([matinput]) {
    height: 40px;

    font-size: 15px;
    padding: 1rem 1.4rem !important;

    border-radius: 1.67rem !important;
    border: thick;
    box-shadow: inset 0 0 2px #9e9e9e;
  }
}

button[mat-flat-button] {
  border-radius: 99px;
  font-weight: 600;
  @apply text-sm;
}

.mat-button-toggle-group {
  @apply text-sm items-center;
  border-radius: 1.67rem !important;
  height: 40px;
}

.mat-button-toggle-label-content {
  line-height: 2.79rem !important;
}
