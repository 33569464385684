.view-content {
  max-height: 100% !important;
  overflow: hidden !important;
}

.component-wrapper {
  margin: 0 auto;
  max-width: $layout-width;
  height: 100%;
}
