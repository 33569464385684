@use '@angular/material' as mat;
@import '../theme-settings';

$warn: map-get($angular-default-theme, warn);

ngx-mat-datepicker-content {
  background: white !important;
}

.tox-mbtn.tox-mbtn--select {
  margin-left: 8px !important;
  margin-right: 8px !important;
}

mat-icon {
  vertical-align: top !important;
}

.mat-mdc-fab {
  transform: scale(0.7);
}

.mat-mdc-progress-spinner {
  width: 20px !important;
  height: 20px !important;

  @apply mr-0.5;
}

.mat-mdc-snack-bar-label, .mdc-snackbar__surface, .mat-mdc-card-content, .mat-mdc-card-header {
  padding: 0 !important;
}

.mat-mdc-button-touch-target {
  display: none;
}

.mat-mdc-form-field-hint {
  display: flex;
  margin-top: 4px;
}

lac-mat-country-selector {
  .country-arrow {
    display: none !important;
  }
}

.mdc-notched-outline__notch {
  display: none !important;
}

.mat-button-toggle-group {
  border-color: black;

  .mat-button-toggle {
    border-color: black;
  }

  .mat-button-toggle-checked {
    background: $redButton-background !important;
    color: white !important;
    font-weight: bold;
  }
}

.spacer {
  flex: 1 1 auto;
}

mat-error {
  color: mat.get-color-from-palette($warn);
}

.mat-mdc-card-header-text {
  width: 100%;
}

.mat-badge-content {
  background-color: darkgray;
}

.mat-mdc-snack-bar-container .mdc-snackbar__surface {
  background-color: transparent !important
}

.mat-mdc-snack-bar-container .mdc-snackbar__surface {
  box-shadow: none !important;
}

.mat-mdc-menu-item-text {
  @apply gap-1 flex;
}
